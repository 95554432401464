import { Menu, Vertical, LogoWrap } from './Nav.Desktop.style'
import { getFocus, useAtom, useGet }                  from 'state/jotai'
import { active_project, vis_desktop_nav }          from 'state/store.global'
import { useFetch_Colors }          from 'hooks/fetch/useFetch_Colors'
import { useFetch_Navigation }      from 'hooks/fetch/useFetch_Navigation'
import { Icon }                     from 'assets/Icons_'
import { Logo }                     from './Nav.Logo'
import { Foldout }                  from './Nav.Desktop.Foldout'
import { _project } from 'state/store.projects'
import { close_drawer } from "state/actions";

export const NavDesktop = () => {
  
  const { clr }           = useFetch_Colors()
  const { nav }           = useFetch_Navigation()
  const [ show, setShow ] = useAtom( vis_desktop_nav )
  
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const closeDrawer = useAtom(close_drawer)[1];

  const icon = {
    color    : clr.icons,
    // icon     : show ? 'close1' : 'three_bars',
    icon     : 'three_bars',
    size     : "xl",
    // text     : show ? buttonsLabel?.filter( btn => btn.UILabelProperty === 'LukMenu')[0]?.Label  : 'Menu',
    // fontSize : '0.8125rem',
    click    : () => {setShow(show ? false : true); closeDrawer("detail");}
  }

  return (
    <>
      <Foldout nav={nav} show={show} setShow={setShow} />
      <Menu clr={clr}>
        {/* <LogoWrap style={{top:60,position:'relative'}}>
          <Logo device='desktop' key='desktopLogo' link='' />
        </LogoWrap> */}
        <Vertical
         className='icon-hover-effect'
       
         >
          {/* <Icon3DEffect> */}
          <Icon {...icon} isFaIcon={true}   />
          {/* </Icon3DEffect> */}
        </Vertical>
      </Menu>
    </>
  )
}