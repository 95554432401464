import React, { useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "assets/Icons_";
import { active_project } from "state/store.global";
import { getFocus, useGet } from "state/jotai";
import { _project } from "state/store.projects";
import { BREAK_ } from "constants";
import { darken } from "polished";


// Styled component for ETStyledButton

export const ETStyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
 box-sizing: border-box;
  height:40px ;
  
  border-radius: ${({ size, borderRadius }) => (borderRadius ? borderRadius : size === "sm" ? "4px" : "8px")};
  padding: ${({ padding }) => (padding ? padding : "12px 40px")};
  line-height: 1;
  user-select: none;
  white-space: nowrap;

  ${BREAK_.md_up} {
    height: 40px;
    width: ${({ width }) => (width ? width : '185px')};
  }

  /* Background color logic */
 
background-color: ${({ variant, clr, pin, type, isIsometry, isMobile, backgroundColor }) => {
  // Check if variant is "tertiary" and set transparent background
  if (variant === "tertiary") {
    return "transparent";
  }
  if(variant === "secondary-1st-varient") {
    return "#ffffffbf"
  }
  if (type === "poly" && pin === 0 && isIsometry) {
    return isMobile && variant === "primary"
      ? `${clr?.primary}`
      : `${clr?.buttons_text}`;
  }
  
  if (type === "poly" && pin === 0) {
    return variant === "tertiary" ? "transparent" : "";
  }

  if (type === "mono" && pin === 0 && isIsometry) {
    return isMobile && variant === "secondary"
      ? `${clr?.buttons_text}`
      : `${clr?.primary}`;
  }

  if (type === "poly" && pin !== 0 && isIsometry) {
    return isMobile && variant === "secondary"
      ? `${clr?.buttons_text}`
      : `${clr?.primary}`;
  }

  if (backgroundColor) {
    return backgroundColor;
  }

  // Default color logic if no other conditions match
  return variant === "primary" ? `${clr?.primary}` : `${clr?.buttons_text}`;
}};

  color: ${({ backgroundColor, variant }) =>
    backgroundColor === "gray" || variant === "secondary" || variant === "secondary-1st-varient" ? "#000000"
      : "#000000"};

  transition: background-color 0.1s ease-in, border 0.1s ease-in,
    box-shadow 0.1s ease-in, color 0.1s ease-in;

 border: ${({ variant }) => {
  if (variant === "tertiary") {
    return "1px solid white"; // White border for tertiary variant
  }
  if(variant === "secondary-1st-varient") {
    return "none";
  }
  if (variant === "primary") {
    return "none"; // No border for primary variant
  }
  return "1px solid #D1D1D1"; // Default border for other variants
}};
&:hover {
  background-color: ${({ variant, clr, pin, type, isIsometry, isMobile, backgroundColor }) => {
    if (variant === "tertiary") {
      return "white"; // White background for tertiary variant
    }
    if(variant === "secondary-1st-varient") {
      return "#FFFFFF"
    }
    if (type === "poly" && pin === 0 && isIsometry) {
      return isMobile && variant === "primary"
        ? darken(0.04, clr?.primary)
        : darken(0.04, clr?.buttons_text);
    }
    
    if (type === "mono" && pin === 0 && isIsometry) {
      return isMobile && variant === "secondary"
        ? darken(0.04, clr?.buttons_text)
        : darken(0.04, clr?.primary);
    }
    if (backgroundColor) return darken(0.04, backgroundColor);
    return variant === "primary" ? darken(0.04, clr?.primary) : darken(0.04, clr?.buttons_text);
  }};
  //  color: ${({ variant }) => (variant === "tertiary" || variant === "secondary-1st-varient" ? "#000000" : "#ffffff")};
    svg {
      color: ${({ variant }) => (variant === "tertiary" || variant === "secondary-1st-varient" ? "#000000" : "#ffffff")}; // Black icon on focus for tertiary
    }
   border: ${({ variant }) =>
    variant === "tertiary" ? "none" :
    variant === "primary" ? "none" :
    variant === "secondary-1st-varient" ? "none" : 
    "1px solid #D1D1D1"};
}
}
  &:focus {
    background-color: ${({ variant, clr, pin, type, isIsometry, isMobile, isStatus, backgroundColor }) => {
      if (isStatus) {
        return null; // Logic to handle status
      }
      if (variant === "tertiary") {
        return "white"; // White background for tertiary variant
      }
      if(variant === "secondary-1st-varient") {
        return  "#ffffff"
      }
      if (type === "poly" && pin === 0 && isIsometry) {
        return isMobile && variant === "primary"
          ? darken(0.04, clr?.primary)
          : clr?.buttons_text;
      }
      if (type === "mono" && pin === 0 && isIsometry) {
        return isMobile && variant === "secondary"
          ? clr?.buttons_text
          : darken(0.04, clr?.primary);
      }
     
      if(backgroundColor) return darken(0.04, backgroundColor);
      return variant === "primary" ? darken(0.04, clr?.primary) : clr?.buttons_text;

    }};
    
  //  color: ${({ variant }) => (variant === "tertiary" || variant === "secondary-1st-varient" ? "#000000" : "#ffffff")};
    svg {
      color: ${({ variant ,skipColorChange}) => ((variant === "tertiary" || variant === "secondary-1st-varient") && !skipColorChange ? "#000000" : "#ffffff")}; // Black icon on focus for tertiary
    }
    box-shadow: ${({ variant, clr, isStatus,boxShadow }) => {
      if(variant === "tertiary"){
        return `0 0 0 1.5px #D1D1D1`; 
      }
    if (variant === "primary" && !isStatus) {
      return `0 0 0 1.5px ${clr?.primary}`; // Primary focus style
    }
    if (variant === "secondary" && !isStatus) {
      return `0 0 0 1.5px #D1D1D1`; // Secondary focus style
    }
    if(variant === "secondary-1st-varient" && !boxShadow) {
      return "0 0 0 1.5px #FFFFFFbf";
    }
    return "none";
  }};
 border: ${({ variant }) => 
    variant === "tertiary" || variant === "secondary-1st-varient" ? "none" : variant === "primary" ? "none" : " "};
}
   
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
// box-shadow: ${({ variant, clr, isStatus }) => {
//     if (variant === "primary" && !isStatus) {
//       return `0 0 0 2.5px ${clr?.primary}`; // Primary focus style
//     }
//     if (variant === "secondary" && !isStatus) {
//       return `0 0 0 1.5px #D1D1D1`; // Secondary focus style
//     }
//     return "none";
//   }};

export default function EusButton({
  width,
  backgroundColor,
  onClick = () => {},
  buttonText,
  otherText,
  icon,
  fontSize,
  rotate,
  isFaIcon,
  iconColor,
  disabled,
  padding,
  iconSize,
  type,
  variant,
  height,
  iconAlign,
  size,
  iconName,
  clr,
  borderRadius,
  buttonlabel,
  skipColorChange ,
   boxShadow

 
}) {
  const buttonRef = useRef(null);
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const handleClick = () => {
    if (buttonRef?.current) {
      buttonRef?.current?.blur();
    }
  };

  return (
    <ETStyledButton
      ref={buttonRef}
      width={width}
      disabled={disabled}
      onClick={() => {
        onClick();
        // handleClick();
      }}
      borderRadius={borderRadius}
      variant={variant}
      clr={clr}
      padding={padding}
      type={type }
      size={size}
      backgroundColor={backgroundColor}
      height={height}
      fontSize={fontSize}
      boxShadow={boxShadow }
    
    >
      {icon && iconAlign === "left" && (
        <Icon
          color={iconColor ?iconColor :  clr?.primary_text} // make sure clr is passed correctly
          icon={iconName}
          isFaIcon={isFaIcon }
          size={isFaIcon ? iconSize ? iconSize : "lg" : size} // for FontAwesome
          skipColorChange={skipColorChange}
        />
      )}
      {buttonlabel ? (
        <span className="eus-button-2">
          {
            buttonsLabel?.filter(
              (btn) => btn?.UILabelProperty === `${buttonText}`
            )[0]?.Label
          }
        </span>
      ) : (
        <span className="eus-button-2">{otherText}</span>
      )}
      {icon && iconAlign === "right" && (
        <Icon
          color={iconColor ?iconColor :  clr?.primary_text} // make sure clr is passed correctly
          icon={iconName}
          isFaIcon={isFaIcon }
          size={isFaIcon ? iconSize ? iconSize : "lg" : size} // for FontAwesome
          skipColorChange={skipColorChange}
        />
      )}
    </ETStyledButton>
  );
}

EusButton.defaultProps = {
  disabled: false,
  type: "button",
  iconAlign: "left",
};
